export default [
  {
    path: '/',
    name: 'index',
    component: () => import('../views/home_copy.vue')
  },
  {
    path: '/activate',
    name: 'activate',
    component: () => import('../views/activate.vue')
  },
  {
    path: '/activate_transit',
    name: 'activate_transit',
    component: () => import('../views/activate_transit.vue')
  },
  {
    path: '/pswd_transit',
    name: 'pswd_transit',
    component: () => import('../views/pswd_transit.vue')
  },
  {
    path: '/pdf_viewer',
    name: 'pdf_viewer',
    component: () => import('../views/pdf_view.vue')
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('../views/test_page.vue')
  }
]
