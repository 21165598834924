export default {
  install: (app, { router, store }) => {
    const files = import.meta.glob('../directives/modules/*.js')
    Object.keys(files).forEach((key) => {
      const name = key.replace(/(\.\/|\.js)/g, '')
      const method = files[key].default
      app.directive(name, (el, binding) =>
        method(el, binding, app, router, store)
      )
    })
  }
}
