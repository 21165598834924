import * as components from '@element-plus/icons-vue'
export default {
  install: (app) => {
    // for (const key in components) {
    //   const componentConfig = components[key];
    //   app.component(componentConfig.name, componentConfig);
    // }

    for (const [key, component] of Object.entries(components)) {
      app.component(key, component)
    }
  }
}
