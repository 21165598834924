import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import App from './App.vue'
import router from './router'

import axios from './plugins/axios'
import elementIcon from '@/plugins/svgicon'

import i18n from './language/i18n.js'
import directives from './directives'
import SliderVerify from 'slider-verify-v3'
import './styles/slider/slider.css'
import 'element-plus/dist/index.css'
import './styles/element-variables.scss'
import 'normalize.css/normalize.css'
import 'nprogress/nprogress.css'
import './styles/commontype.css'
import './styles/markdown/markdown-it-index.css'
import dayjs from 'dayjs'
import { createPinia } from 'pinia'
import store from './store'


const app = createApp(App)
app.use(createPinia())
app.config.warnHandler = () => null
app.config.globalProperties.day = dayjs // 全局挂载
app.use(i18n)
app.use(router)
app.use(ElementPlus)
app.use(elementIcon)
app.use(SliderVerify)
app.use(axios, { router, store, opt: 'VE_API' })
app.use(directives, { router, store })
app.mount('#app')
