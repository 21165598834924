import zh from './zh.js'
import en from './en.js'

const messages = {
  en,
  zh
}

export default messages

