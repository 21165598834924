<template>
  <el-config-provider :locale="proxy.$i18n.locale === 'en' ? en : zh">
    <el-alert
      v-if="visited"
      style="
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 3.3rem;
        z-index: 99999;
        background: #000000db;
      "
      center
      show-icon
      type="warning"
    >
      <template #title>
        选择建议浏览器
      </template>
    </el-alert>
    <router-view :key="$route.fullPath" />
  </el-config-provider>
</template>

<script>
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import en from 'element-plus/dist/locale/en.mjs'

import { inject, provide, ref, getCurrentInstance } from 'vue'
import { useI18n } from 'vue-i18n'
import useStatusStore from './store/index.js'

export default {
  components: {
  },
  name: 'App',
  setup() {
    const { t } = useI18n()
    const language = ref('zh')
    const { proxy } = getCurrentInstance()
    provide('language', language)
    provide('changeLanguage', data => {
      language.value = data
    })

    const visited = ref(false)
    if (sessionStorage.getItem('visited') === null) {
      // 第一次进入网站的逻辑
      sessionStorage.setItem('visited', 'true')
      const userAgent = navigator.userAgent
      let browserName, browserVersion
      if (/Firefox/i.test(userAgent)) {
        browserName = 'Mozilla Firefox'
        browserVersion = userAgent.match(/Firefox\/([0-9]+)/)[1]
      } else if (/Chrome/i.test(userAgent)) {
        browserName = 'Google Chrome'
        browserVersion = userAgent.match(/Chrome\/([0-9]+)/)[1]
      } else if (/Edge/i.test(userAgent)) {
        browserName = 'Microsoft Edge'
        browserVersion = userAgent.match(/Edge\/([0-9]+)/)[1]
      } else if (/Safari/i.test(userAgent)) {
        browserName = 'Apple Safari'
        browserVersion = userAgent.match(/Safari\/([0-9]+)/)[1]
      } else {
        browserName = 'Unknown'
        browserVersion = '0'
        visited.value = true
      }
      console.log('Browser: ' + browserName + ', Version: ' + browserVersion)
    }
    return {
      t,
      zh: zhCn,
      en,
      proxy,
      visited
    }
  }
}
</script>
<style lang="css" scoped>
:deep(.el-alert--warning.is-light) {
  background-color: #000000bd;
}
:deep(.el-alert__title) {
  line-height: 20px;
  font-size: 1.1rem;
}
</style>
